import { Alpine as AlpineType } from 'alpinejs';

export default (Alpine: AlpineType) => {
  Alpine.data('header', (props: any) => ({
    showAnnouncementBar: true,
    isShopOpen: false,
    isAboutOpen: false,
    showHeader: true,

    init() {
      if (props.hideHeader) {
        let prevScrollPos = window.scrollY;
        const body = document.querySelector("body");

        window.onscroll = (e) => {
          let currentScrollPos = window.scrollY;

          if (prevScrollPos > currentScrollPos && currentScrollPos > 99) {
            this.showHeader = true;
            body?.classList.add("is-scroll-up");
            body?.classList.remove("is-scroll-down");
          } else if (currentScrollPos < 100) {
            this.showHeader = true;
            body?.classList.remove("is-scroll-up");
          } else {
            this.showHeader = false;
            body?.classList.add("is-scroll-down");
            body?.classList.remove("is-scroll-up");
          }

          prevScrollPos = currentScrollPos;
        }
      }
    },
    openShop() {
      this.isAboutOpen = false;
      this.isShopOpen = true;
      document.getElementsByTagName('html')[0].classList.add("is-shop-menu-visible");
    },
    openAbout() {
      this.isShopOpen = false;
      this.isAboutOpen = true;
    },
    closeMegamenu() {
      this.isShopOpen = false;
      this.isAboutOpen = false;
      document.getElementsByTagName('html')[0].classList.remove("is-shop-menu-visible");
    },
    closeAnnouncementBar() {
      const headerPlaceholder = document.querySelector<HTMLElement>('.header-placeholder');

      if (headerPlaceholder) {
        headerPlaceholder.style.height = `${headerPlaceholder.offsetHeight - 45}px`;
      }

      this.showAnnouncementBar = false;
      const header = document.querySelector('header.header  .header__content');
      if (header != null) {
        setTimeout(() => {
          document.body.style.setProperty('--lsg-header-height', `${header.offsetHeight}px`);
        }, 10);
      }
     }
  }));
};
